import { EnBookOrderBy, EnSort } from 'src/enums'

export const TOKEN_EXPIRED_AT = 60 * 60 * 24 * 30 * 3
export const SEARCH_MIN_LENGTH = 3
export const YURI_GENRE_ID = 24
export const YAOI_GENRE_ID = 2

export const DEFAULT_GRID_COUNT = 20
export const UPDATES_GRID_COUNT = 20
export const CHAPTER_LIST_COUNT = 20
export const COMMENT_LIST_COUNT = 20
export const BOOKMARK_LIST_COUNT = 20
export const CATALOG_GRID_COUNT = 30
export const SEARCH_GRID_COUNT = 24
export const TOP_GRID_COUNT = 20
export const CATEGORY_GRID_COUNT = 20
export const MAX_PAGE_SIZE = 100

export const DEFAULT_BOOKS_SORT = EnSort.DESC
export const DEFAULT_BOOKS_ORDER_BY = EnBookOrderBy.UPDATE
export const baseApi = process.env.NEXT_PUBLIC_ENV_BACKEND_DOMAIN + '/api2'
export const BOOK_MIN_COUNT_TAGS = 10
export const FORBIDDEN_GENRES_IN_RU = [2, 24, 35, 25]

export const DEFAULT_USER_IMAGE = '/images/users/default-user.jpg'
export const DEFAULT_COURSE = 100
export const LINK_TO_VPN_POST = 'https://t.me/topmangachat/3131/3166'
export const EMAIL = 'hotmangaonline@gmail.com'
export const IS_WINTER_STYLE = false

// we have script ad-provider which immitate custom adv
export const ID_FOR_TEST_ADBLOCK = 'WFiZRSOJqNEu'
export const API = 'api-frontend'

// payment
export const BRONZE_SUBSCRIPTION_ONE = 399
export const SILVER_SUBSCRIPTION_ONE = 1080
export const GOLD_SUBSCRIPTION_ONE = 3830

export const BRONZE_SUBSCRIPTION_RECCURENT = 299
export const SILVER_SUBSCRIPTION_RECCURENT = 804
export const GOLD_SUBSCRIPTION_RECCURENT = 2870

export const BRONZE_SUBSCRIPTION_SPECIAL = 240
export const SILVER_SUBSCRIPTION_SPECIAL = 780
export const GOLD_SUBSCRIPTION_SPECIAL = 2400

export const CHAPTER_COST = 12
