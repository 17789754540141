import { signOut } from 'next-auth/react'
import { API } from 'src/config'
import { RequestInit } from './types'

export type ErrorType<T> = { [key in keyof T]: string } | { message?: string }

export const serverFetch = async <T extends object>(props: {
  url: string
  params?: RequestInit
  cookie?: any
  isContentTypeJson?: boolean
}) => {
  return new Promise(
    async (
      resolve: (value: T) => void,
      reject: (value: ErrorType<T>) => void,
    ) => {
      const { url, isContentTypeJson = true } = props
      const params = props.params ?? {}

      const headers: Record<string, string> =
        params && params.headers ? params.headers : {}

      if (isContentTypeJson) {
        headers['Content-Type'] = 'application/json'
      }

      params.headers = headers
      if (props.cookie) {
        params.headers.cookie = props.cookie
      }

      const res = await fetch(
        `${process.env.NEXT_PUBLIC_ENV_DOMAIN}/${API}${url}`,
        {
          ...params,
          cache: 'no-store',
        },
      )

      if (typeof window !== 'undefined' && res.status === 401) {
        await signOut()
      }

      if ([404, 405].includes(res.status)) {
        return reject({ message: `${res.statusText} url: ${url}` })
      }

      let body: (T & { errors: ErrorType<T> }) | null = null

      try {
        body = await res.json()

        if (res.ok) {
          return resolve(body as T)
        }
      } catch (e) {
        console.log('e', e)
        console.log(
          'serverFetch',
          `${process.env.NEXT_PUBLIC_ENV_DOMAIN}/${API}${url}`,
        )
      }

      reject({
        ...(body?.errors ?? { message: res.statusText }),
      })
    },
  )
}
